/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout, { Container } from "components/layout"
import Computer from "components/svgs/computer"
import Hero from "components/hero"
import ImageRow from "components/image-row"
import ImageText from "components/image-text"
import List from "components/list"
import SpotlightTitle from "components/spotlight-title"
import { TileInfo, TileWrapper } from "components/tiles"
import Title from "components/title"

export const query = graphql`
  query AcademicsQuery {
    heroImage: file(relativePath: { eq: "hero/academics.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    techs: file(relativePath: { eq: "academics/techs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    classroom: file(relativePath: { eq: "academics/classroom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pipes: file(relativePath: { eq: "academics/pipes.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lab: file(relativePath: { eq: "academics/lab.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    field: file(relativePath: { eq: "academics/field.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Academics = ({ data }) => (
  <Layout
    pageTitle="Academics & Applied Research"
    description="Students and faculty in every discipline are engaged in sustainability through coursework, applied research and experiential learning programs."
  >
    <Title icon={<Computer />}>Academics &amp; Applied Research</Title>
    <Hero fluid={data.heroImage.childImageSharp.fluid} />

    <Container>
      <div sx={{ maxWidth: ["100%", "70%"] }}>
        <Styled.p>
          Mohawk offers students in every discipline the opportunity to
          integrate sustainability into their studies through coursework,
          applied research and experiential learning programs. Many students
          work on sustainability-focused applied research projects as part of
          their capstone courses, co-ops and part-time jobs.
        </Styled.p>
        <Styled.p>
          IDEAWORKS, Mohawk's applied research hub, supports innovative research
          that provides expertise and support to companies to develop solutions
          that improve efficiency and resilience.
        </Styled.p>
      </div>
      <ImageRow>
        <Img fluid={data.techs.childImageSharp.fluid} />
        <Img className="wide" fluid={data.classroom.childImageSharp.fluid} />
        <Img fluid={data.pipes.childImageSharp.fluid} />
      </ImageRow>
    </Container>

    <div sx={{ bg: "muted" }}>
      <Container>
        <Styled.h2>Key Initiatives</Styled.h2>
        <List icon={<Computer />}>
          <Fragment>Energy and Power Innovation Centre</Fragment>
          <Fragment>
            Establishment of IDEAWORKS Research Chair in Sustainability
          </Fragment>
          <Fragment>
            Establishment of Natural Sciences andEstablishment of NSERC
            Industrial Research Chair for Internet of Things (IoT)
          </Fragment>
          <Fragment>
            Launch of Climate Change Management for Business, a free
            micro-course available to all students
          </Fragment>
          <Fragment>Additive Manufacturing Innovation Centre</Fragment>
        </List>
      </Container>
    </div>

    <Container wide={true} spaced={false}>
      <TileWrapper>
        <TileInfo bg="darkPrimary" color="background">
          <Styled.p>
            <Styled.h2 as="span">100%</Styled.h2>
            <br />
            of academic departments offer sustainability courses
          </Styled.p>
        </TileInfo>
        <TileInfo bg="primary" color="background">
          <Styled.p>
            <Styled.h2 as="span">78%</Styled.h2>
            <br />
            of research-producing departments engaged in sustainability research
          </Styled.p>
        </TileInfo>
        <TileInfo bg="accentTwo" color="dark">
          <Styled.p>
            <Styled.h2 as="span">15</Styled.h2>
            <br />
            industry applied research projects with a sustainability focus
            through IDEAWORKS
          </Styled.p>
        </TileInfo>
        <TileInfo bg="secondary" color="dark">
          <Styled.p>
            <Styled.h2 as="span">340+</Styled.h2>
            <br />
            students participated in sustainability-focused capstone projects in
            2018/2019
          </Styled.p>
        </TileInfo>
      </TileWrapper>

      <ImageText image={data.lab.childImageSharp.fluid}>
        <Styled.h2>
          Validating the Effectiveness of an Energy-Efficient LED Light
        </Styled.h2>
        <Styled.h3>Research Area</Styled.h3>
        <Styled.p>Energy and Power Innovation Centre</Styled.p>
        <Styled.h3>Funder</Styled.h3>
        <Styled.p>Ontario Centres of Excellence</Styled.p>
        <Styled.p>
          LED lighting is a standard feature in Canadian buildings due to their
          energy efficiency and lower operating costs. However, as LEDs are DC
          devices, energy is still lost when AC power from the grid is used to
          power the lights. Burlington-based iLLUMA-Drive developed an
          innovative low voltage smart control system (CENSE) that can reduce
          the energy that is lost during the AC/ DC power conversion.
        </Styled.p>
        <Styled.p>
          iLLUMA-Drive asked Mohawk College’s Energy and Power Innovation Centre
          to conduct the validation and testing of their system. The project
          results confirmed that powering LEDs using the CENSE unit provided
          lower losses and higher light quality than conventional LEDs.
        </Styled.p>
      </ImageText>
    </Container>
    <SpotlightTitle>
      Using the Internet of Things (IoT) to create a modern smart farm
    </SpotlightTitle>
    <Container wide={true} spaced={false}>
      <ImageText
        image={data.field.childImageSharp.fluid}
        bg="background"
        color="dark"
      >
        <Styled.h3>Research Area</Styled.h3>
        <Styled.p>Sensor Systems and Internet of Things (IoT) Lab</Styled.p>
        <Styled.h3>Funder</Styled.h3>
        <Styled.p>NSERC</Styled.p>
        <Styled.p>
          Smart farming is key for the future of agriculture, as it provides the
          agricultural industry with the infrastructure to leverage advanced
          technology—including big data, the cloud and the internet of things
          (IoT)—for tracking, monitoring, automating and analyzing operations.
        </Styled.p>
        <Styled.p>
          As champions of sustainable agriculture, Schuyler Farms Limited in
          Simcoe, ON invest heavily in a number of environmentally friendly,
          sustainable farming operations, such as strip tilling and rotational
          grazing.
        </Styled.p>
        <Styled.p>
          The owners of the farm were interested in exploring if technology
          could make their farm more sustainable, by preventing the spread of
          fungal disease in their apples and reducing crop waste and pesticide
          usage.
        </Styled.p>
        <Styled.p>
          The research team at Mohawk College’s Sensor Systems and Internet of
          Things (IoT) lab developed a software system that could be used in
          conjunction with a number of small, localized weather stations at the
          farm.
        </Styled.p>
        <Styled.p>
          These weather stations are able to monitor the moisture levels on the
          leaves of the apple, calculating the risk of a fungal infection and
          alerting the farm where and when localized spraying is necessary.
        </Styled.p>
        <Styled.p>
          The collaboration with Mohawk College has helped Schuyler Farms
          Limited improve the quality of their apples, reduce food waste, and
          lower their maintenance and monitoring costs.
        </Styled.p>
      </ImageText>
    </Container>
  </Layout>
)

export default Academics
